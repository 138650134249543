import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanyModel, CompanyService } from '../core/database/company.service';
import { OfflineService } from '../core/database/offline.service';
import { SystemConfigurationService } from '../core/database/system-configuration.service';
import {
  TachographModel,
  TachographService,
  TachographStatus,
} from '../core/database/tachograph.service';
import { UpdateService } from '../core/database/update.service';
import { UserCompaniesService } from '../core/database/user-companies.service';
// import * as M from 'materialize-css';
import { UserModel, UserService } from '../core/database/user.service';
import { VehicleService } from '../core/database/vehicle.service';
import { AuthService } from '../core/service/auth.service';
import { SyncService } from '../core/service/sync.service';
import { UtilsService } from '../core/service/utils.service';
import { JourneyModel, JourneyService } from '../core/database/journey.service';
import moment from 'moment';
import Swal from 'sweetalert2';
import { LicensesService } from '../core/database/licenses.service';
import { ConfigurationsModel, ConfigurationsService } from '../core/database/configurations.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('slideOut', { static: false }) slideOut: ElementRef | null = null;
  @ViewChild('modal', { static: false }) modal: ElementRef | null = null;
  @ViewChild('modalDemo', { static: false }) modalDemo: ElementRef | null = null;

  version = environment.version;
  user: UserModel | null = null;
  company: CompanyModel | null = null;
  syncLoading: boolean;
  showLoadingMessage: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  deferredPrompt: any = null;
  showInstallBanner: boolean = false;

  syncCheckInterval: any;
  offilineCheckInterval: any;
  inconsistentTachographs: TachographModel[] = [];
  foundInconsistent: boolean = false;
  switchedDemo: boolean = false;

  constructor(
    public utilsSrv: UtilsService,
    private readonly authSrv: AuthService,
    private readonly syncSrv: SyncService,
    private readonly updateSrv: UpdateService,
    private readonly vehiclesSrv: VehicleService,
    public tachographSrv: TachographService,
    private readonly userSrv: UserService,
    private readonly companySrv: CompanyService,
    private readonly userCompaniesSrv: UserCompaniesService,
    private readonly journeySrv: JourneyService,
    private readonly systemConfigurationSrv: SystemConfigurationService,
    private router: Router,
    readonly offlineService: OfflineService,
    private readonly licensesSrv: LicensesService,
    private readonly configurationsSrv: ConfigurationsService,
  ) { }

  ngOnInit(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      // if app can be installed, assign the event to deferred prompt variable
      this.deferredPrompt = e;
      this.showInstallBanner = true;
    });

    if (!environment.production) {
      this.version = environment.version + ' - Dev';
    }
  }

  async installPwa(install: boolean) {
    if (install) {
      if (this.deferredPrompt !== null) {
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          this.deferredPrompt = null;
          this.showInstallBanner = false;
        }
      } else {
        this.showInstallBanner = false;
        console.log('deferred prompt is null [Website cannot be installed]');
      }
    } else {
      this.showInstallBanner = false;
    }
  }

  ngOnDestroy(): void {
    this.onlineSubscription.unsubscribe();

    this.destroy$.next(false);
    this.destroy$.unsubscribe();

    clearInterval(this.syncCheckInterval);
    clearInterval(this.offilineCheckInterval);
  }

  sidebar: any = null;
  onlineSubscription: any;
  modalElem: any = null;
  modalElemDemo: any = null;

  ngAfterViewInit(): void {
    this.modalElem = M.Modal.init(this.modal?.nativeElement, {
      startingTop: '30%',
      preventScrolling: true,
      dismissible: false,
    });
    this.modalElemDemo = M.Modal.init(this.modalDemo?.nativeElement, {
      startingTop: '30%',
      preventScrolling: true,
      dismissible: false,
    });

    this.sidebar = M.Sidenav.init(this.slideOut?.nativeElement);

    this.user = this.utilsSrv.getCurrentUser();
    if (this.user) {
      this.company = this.utilsSrv.getCurrentCompany();
      if (!this.company) this.router.navigate(['select-company']);

      if (!this.user.photo || this.user.photo == '') {
        this.user.photo = this.utilsSrv.getInitialsAvatar();
      }

      this.syncData();

      /* this.user.company?.isDemo==true */
      this.syncCheckInterval = setInterval(() => {
        this.syncCheck();
      }, 15000);

      this.offilineCheckInterval = setInterval(() => {
        this.offlineCheck();
      }, 60000);
    } else {
      this.authSrv.logout();
    }

    this.listenToEvents(this.offlineService);

    this.syncSrv.syncDataShared
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value == true) this.syncData();
      });

    this.syncSrv.showLoadingShared
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.syncLoading = value;
      });
  }

  private listenToEvents(offlineService: OfflineService) {
    this.onlineSubscription = offlineService.connectionChanged.subscribe(
      (online) => {
        this.utilsSrv.toast(
          `Está agora em modo ${online ? 'online' : 'offline'}`
        );
        console.log(`The application is now ${online ? 'online' : 'offline'}`);
        if (!online) localStorage.setItem('offlineTime', moment().toISOString());
        if (online) {
          // POR AQUI OPÇÃO PARA SINCRONIZAR DADOS
          this.syncData();
          if (localStorage.getItem('offlineTime')) localStorage.removeItem('offlineTime')
        }
      }
    );
  }

  offlineCheck() {
    if (!this.utilsSrv.isOnline()) {
      //is offline
      const offlineTime = localStorage.getItem('offlineTime');
      if (offlineTime) {
        const now = moment();
        const diff = now.diff(moment(offlineTime), 'hours');
        if (diff >= 2) {
          console.log('passed 2 hours in offline');
          this.utilsSrv.toast(`Está offline à mais de 2 horas e poderá perder registos.`);
          localStorage.setItem('offlineTime', moment().toISOString());
        }
      }
    }
  }

  async syncCheck() {
    if (!this.utilsSrv.isOnline() || !this.user || this.syncLoading == true
      || this.foundInconsistent) {
      return;
    }

    const syncData: any = {
      userId: this.user.id,
      lastUpdate: null,
      lastTachographId: null,
      licenseId: null,
      licenseTo: null,
      companyId: null
    };

    // const lastUpdate = await this.updateSrv.getLastestUpdate(this.user.id);
    // LastUpdate based on last register with pending false
    const lastUpdate = await this.tachographSrv.getLastestTachographSyncedTsUpdated(syncData.userId);
    // syncData.lastUpdate = lastUpdate
    //   ? lastUpdate?.tsUpdated
    //   : null;
    // console.log('SYNC CHECK LAST UPDATE ' + syncData?.lastUpdate ? moment(syncData?.lastUpdate).toISOString() : syncData?.lastUpdate);
    syncData.lastTachographId = lastUpdate
      ? lastUpdate?.id
      : null;
    syncData.lastTachographTsUpdated = lastUpdate
      ? lastUpdate?.tsUpdated
      : null;
    if (this.company) {
      const companyUser = await this.userCompaniesSrv.getUserCompany(this.user?.id, this.company?.id);
      if (companyUser) {
        const activeLicense = await this.licensesSrv.getActiveLicenseByUserAndCompany(companyUser.id, this.company?.id)
        syncData.licenseId = activeLicense?.id;
        syncData.licenseTo = activeLicense?.to;
      }
      syncData.companyId = this.company?.id;
    }

    console.log('SYNC CHECK LAST ID ' + syncData?.lastTachographId || '');

    this.syncSrv.syncCheck(syncData).subscribe(
      async (res: any) => {
        if (res?.data && Array.isArray(res.data) && res.data.length > 0) {
          if (res.data[0] && res.data[0]?.serverDate) this.syncSrv.serverDate = moment(res.data[0].serverDate);
          console.log('updated', res.data[0]?.updated);
          if (res.data[0] && res.data[0]?.updated == false) {
            this.syncData();
          }
        }
      },
      (err: any) => {
        console.log(
          '%clayout.component.ts line:309 err',
          'color: #007acc;',
          err
        );
      }
    );
  }

  async syncData() {
    if (!this.utilsSrv.isOnline() || !this.user || this.syncLoading == true)
      return;

    console.log('SYNC DATA START ' + moment().format('HH:mm:ss.SSS'));

    const syncData: any = {
      userId: this.user.id,
      user: {},
      vehicles: [],
      tachographs: [],
      journeys: [],
      // userCompanies: [],
      lastUpdate: null,
      companyId: null,
      isDemo: null
    };

    const userAux = await this.userSrv.getStoredUser(this.user.id);
    if (userAux) {
      syncData.user = userAux;
    } else {
      return;
    }
    let company = new CompanyModel();
    if (this.company) company = await this.companySrv.getCompanyById(this.company?.id)
    this.syncLoading = true;
    syncData.companyId = company?.id;
    syncData.isDemo = company?.isDemo;

    // const lastUpdate = await this.updateSrv.getLastestUpdate(this.user.id);
    // LastUpdate based on last register with pending false
    const lastUpdate = await this.tachographSrv.getLastestTachographSyncedTsUpdated(syncData.userId);
    // syncData.lastUpdate = lastUpdate
    //   ? lastUpdate?.tsUpdated
    //   : null;
    // console.log('SYNC CHECK LAST UPDATE ' + syncData?.lastUpdate ? moment(syncData?.lastUpdate).toISOString() : syncData?.lastUpdate);
    syncData.lastTachographId = lastUpdate
      ? lastUpdate?.id
      : null;
    syncData.lastTachographTsUpdated = lastUpdate
      ? lastUpdate?.tsUpdated
      : null;

    // syncData.vehicles = (await this.vehiclesSrv.getPendingVehicles()) as any;
    syncData.journeys = (await this.journeySrv.getPending(
      this.user.id
    )) as any;

    syncData.tachographs = (await this.tachographSrv.getPendingTachographs(
      this.user.id
    )) as any;

    if (syncData.tachographs?.length > 0) {
      for (const t of syncData.tachographs) {
        if (
          (t as any).journey != null &&
          syncData.journeys.filter((j: any) => j.id == (t as any).journey)
            .length == 0
        ) {
          const j: any = await this.journeySrv.getPendingJourney(
            (t as any).journey
          );
          if (j) {
            syncData.journeys.push(j);
          }
        }
      }
    }

    this.syncSrv.syncData(syncData).subscribe(
      async (res: any) => {
        console.log('SYNC DATA RECEIVED ' + moment().format('HH:mm:ss.SSS'));
        //console.time('SYNC FUNCTION');
        if (res.data.length > 0) {
          this.syncSrv.serverDate = moment(res.data[0].serverDate);
          this.showLoadingMessage = res.data[0].showMessage;

          for (const conf of res.data[0].configurations) {
            await this.configurationsSrv.updateOrCreateConfigurations(conf);
          }

          if (res.data[0].user && res.data[0].user?.id) {
            await this.userSrv.updateOrCreateUser(res.data[0].user);
            //console.timeLog('updateOrCreateUser');
          }

          let companySync = res.data[0].companies.find(
            (c: CompanyModel) => c.id == this.company?.id
          );
          if ((this.company && company?.isDemo === true && companySync?.isDemo === false)) {
            await this.tachographSrv.deleteTachographByCompany(res.data[0].user?.id, this.company?.id);
            await this.journeySrv.deleteJourneyByUserAndCompany(res.data[0].user?.id, this.company?.id);
            this.modalElemDemo.open();
          }
          //console.time('updateOrCreateCompany');
          for (const c of res.data[0].companies) {
            await this.companySrv.updateOrCreateCompany(c);
          }

          //console.time('deleteAndInsertCompanies');
          await this.userCompaniesSrv.deleteAndInsertCompanies(
            this.user!.id,
            res.data[0].companyUsers
          );

          if (res.data[0].companyUsers.length == 0) {
            this.utilsSrv.toast('Utilizador não tem nenhuma empresa ativa');
            this.authSrv.logout();
          }

          if (sessionStorage.getItem('nifAuthNif'))
            sessionStorage.removeItem('nifAuthNif');
          if (
            (res.data[0].user.email == null || res.data[0].user.email == '') &&
            res.data[0].user.firstLogin == true
          ) {
            this.utilsSrv.toast('Autenticação por NIF/Pin expirada');
            sessionStorage.setItem('nifAuthNif', res.data[0].user.nif);
            this.authSrv.logout();
          }

          if (res.data[0].licenses.length == 0) {
            this.utilsSrv.toast('Utilizador não tem licenças ativas');
            this.authSrv.logout();
          }

          //console.time('updateOrCreateVehicle');
          for (const v of res.data[0].vehicles) {
            await this.vehiclesSrv.updateOrCreateVehicle(v);
          }
          //console.time('updateOrCreateLicense');          
          for (const l of res.data[0].licenses) {
            await this.licensesSrv.updateOrCreateLicense(l);
          }

          //console.time('updateOrCreate');
          for (const j of res.data[0].journeys) {
            if (j?.deleted) {
              await this.journeySrv.deleteJourney(j.id);
            } else {
              if (j.date) j.date = new Date(j.date);
              if (j.endDate) j.endDate = new Date(j.endDate);
              await this.journeySrv.updateOrCreate(j);
            }
          }

          //console.time('deleteAndInsertSystemConfigurations');
          if (res.data[0].systemConfigurations.length > 0) {
            await this.systemConfigurationSrv.deleteAndInsertSystemConfigurations(
              res.data[0].systemConfigurations
            );
          }

          const tachographs = this.utilsSrv.sortArrayByDate(
            res.data[0].tachographs,
            'date',
            -1
          );
          const lastTachograph =
            tachographs && Array.isArray(tachographs) && tachographs.length > 0
              ? tachographs[0]
              : null;

          // Check for driving tachograph and actual journey
          const drivingIndex = lastTachograph?.journey
            ? tachographs.findIndex(
              (f) =>
                f?.status &&
                f?.status === TachographStatus.CONDUCAO &&
                f?.journeyId === lastTachograph?.journey
            )
            : -1;
          console.log('drivingIndex', drivingIndex);
          // console.warn('drivingTach', tachographs[drivingIndex]);

          let updatedDate = false;
          let index = 0;
          //console.time('updateOrCreateTachograph');
          this.inconsistentTachographs = tachographs.filter(f => f?.deleted);
          this.foundInconsistent = this.inconsistentTachographs?.length > 0 ? true : false;

          for (const t of tachographs) {
            index++;
            if (t?.deleted) {
              await this.tachographSrv.deleteTachograph(t.id);
            } else {
              if (t.date) t.date = new Date(t.date);
              if (t.endDate) t.endDate = new Date(t.endDate);
              if (t.originalDate) t.originalDate = new Date(t.originalDate);
              if (t.tsCreated) t.tsCreated = new Date(t.tsCreated);
              if (t.tsUpdated) t.tsUpdated = new Date(t.tsUpdated);
              await this.tachographSrv.updateOrCreateTachograph(t);
            }

            // Check for the 1st 5 tachographs with or without driving or until driving is equal to tachograph position
            if (
              (index == 4 && drivingIndex <= index) ||
              index == drivingIndex
            ) {
              console.log('loading off: ', index);
              this.syncLoading = false;
              await this.updateSrv.createUpdate(this.user!.id);
              updatedDate = true;
              if (this.inconsistentTachographs.length > 0) {
                this.modalElem.open();
                console.log('open modal');
              } else this.modalElem.close();
            }
          }

          // if (!updatedDate) {
          //   //console.time('createUpdate');
          //   await this.updateSrv.createUpdate(this.user!.id);
          //   //console.timeLog('createUpdate');
          // }

          const dateDelete = moment().subtract(1, 'months').toDate();
          await this.tachographSrv.deleteTachographsOlderThanDate(
            this.user!.id,
            dateDelete
          );

          if (res.data[0].user.privacyPolicyAccepted == false) {
            this.router.navigate(['privacy-policy']);
          }

          if (res.data[0].companyUsers.length > 1) {
            this.router.navigate(['select-company']);
          }

          if(res.data[0].companies?.length == 1 && res.data[0].companies?.[0]) {
            localStorage.setItem('currentCompany', JSON.stringify(res.data[0].companies?.[0]));
          }

          if (this.company && this.user) {
            const companyUser = await this.userCompaniesSrv.getUserCompany(this.user?.id, this.company?.id);
            if (companyUser) {
              await this.licensesSrv.AlterStatusLicenseByUserAndCompany(companyUser?.id, this.company?.id, res.data[0].licenses)
            }
          }
        }
        //console.timeLog('SYNC FUNCTION');

        console.log('SYNC DATA ENDED ' + moment().format('HH:mm:ss.SSS'));
        this.syncLoading = false;
        if (this.inconsistentTachographs.length > 0) {
          this.modalElem.open();
          console.log('open modal 1');
        } else this.modalElem.close();
      },
      (err: any) => {
        console.log(
          '%clayout.component.ts line:81 err',
          'color: #007acc;',
          err
        );
        this.syncLoading = false;
      }
    );
  }

  closeModal() {
    this.modalElem.close();
    this.foundInconsistent = false;
  }

  async closeIsDemoModal() {
    this.modalElemDemo.close();
  }

  openSidenav() {
    this.sidebar.open();
  }

  clickHome() {
    this.sidebar.close();
    return this.router.navigate(['home']);
  }

  clickChangeCompany() {
    this.sidebar.close();
    return this.router.navigate(['select-company']);
  }

  clickTacographHistory() {
    this.sidebar.close();
    return this.router.navigate(['tachograph-history']);
  }

  clickHelpPage() {
    this.sidebar.close();
    return this.router.navigate(['help-page']);
  }

  clickPrivacyPolicy() {
    this.sidebar.close();
    return this.router.navigate(['privacy-policy-page']);
  }

  clickExport() {
    this.sidebar.close();
    return this.router.navigate(['export']);
  }

  clickLogout() {
    this.sidebar.close();
    this.authSrv.logout();
  }

  clickProfile() {
    this.sidebar.close();
    return this.router.navigate(['profile']);
  }
}
