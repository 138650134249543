import { Injectable } from '@angular/core';
import { OfflineService } from './offline.service';
import Dexie from 'dexie';
import relationships from 'dexie-relationships';
import { DatabaseSeedsService } from './database-seeds.service';
import { environment } from './../../../environments/environment';
import { RestType } from './tachograph.service';
import { CompanyStatus } from './company.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  db: any;
  constructor(readonly databaseSeedsSrv: DatabaseSeedsService) {
    this.createIndexedDatabase();
  }

  private createIndexedDatabase() {
    this.db = this.db = new Dexie(environment.DB, { addons: [relationships] });

    this.db.version(1).stores({
      company: 'id,name',
      user: 'id,name,email,nif,photo,hashPin,salt,token,expiresIn',
      vehicle:
        'id,licensePlate,brand,model,deleted,companyId -> company.id,pending',
      tachograph:
        'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending',
      update: '++id,userId -> user.id,date',
    });

    this.db
      .version(2)
      .stores({
        tachograph:
          'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending,restType',
      })
      .upgrade((tx: any) => {
        return tx
          .table('tachograph')
          .toCollection()
          .modify((tachograph: any) => {
            tachograph.restType = RestType.BREAK;
          });
      });

    this.db
      .version(3)
      .stores({
        tachograph:
          'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending,restType,latitude,longitude',
      })
      .upgrade((tx: any) => {
        return tx
          .table('tachograph')
          .toCollection()
          .modify((tachograph: any) => {
            tachograph.latitude = null;
            tachograph.longitude = null;
          });
      });

    this.db
      .version(4)
      .stores({
        vehicle:
          'id,licensePlate,brand,model,deleted,companyId -> company.id,pending,km',
        systemConfiguration: 'id,version,text,type',
        user: 'id,name,email,nif,photo,hashPin,salt,token,expiresIn,privacyPolicyAccepted,privacyDate,companyId -> company.id',
      })
      .upgrade(async (tx: any) => {
        await tx
          .table('vehicle')
          .toCollection()
          .modify((vehicle: any) => {
            vehicle.km = 0;
          });
        await tx
          .table('user')
          .toCollection()
          .modify((user: any) => {
            user.privacyPolicyAccepted = false;
            user.privacyDate = null;
            user.companyId = null;
          });
        return null;
      });

    this.db
      .version(5)
      .stores({
        user: 'id,name,email,nif,photo,hashPin,salt,token,expiresIn,privacyPolicyAccepted,privacyDate,companyId -> company.id,category',
      })
      .upgrade(async (tx: any) => {
        await tx
          .table('user')
          .toCollection()
          .modify((user: any) => {
            user.category = '';
          });
        return null;
      });

    this.db
      .version(6)
      .stores({
        vehicle:
          'id,licensePlate,brand,model,deleted,companyId -> company.id,pending,km,vehicle,category',
      })
      .upgrade(async (tx: any) => {
        await tx
          .table('vehicle')
          .toCollection()
          .modify((vehicle: any) => {
            vehicle.category = '';
          });
        return null;
      });

    this.db.version(7).stores({
      userCompanies: 'id,userId -> user.id,companyId -> company.id',
    });

    this.db
      .version(8)
      .stores({
        userCompanies: 'id,userId -> user.id,companyId -> company.id,pending',
      })
      .upgrade(async (tx: any) => {
        await tx
          .table('userCompanies')
          .toCollection()
          .modify((userCompanies: any) => {
            userCompanies.pending = false;
          });
        return null;
      });

    this.db
      .version(9)
      .stores({
        tachograph:
          'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending,restType,latitude,longitude,journey',
      })
      .upgrade((tx: any) => {
        return tx
          .table('tachograph')
          .toCollection()
          .modify((tachograph: any) => {
            tachograph.journey = 0;
          });
      });

    this.db
      .version(10)
      .stores({
        tachograph:
          'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending,restType,latitude,longitude,journey,teamDriving',
      })
      .upgrade((tx: any) => {
        return tx
          .table('tachograph')
          .toCollection()
          .modify((tachograph: any) => {
            tachograph.teamDriving = false;
          });
      });

    this.db
      .version(11)
      .stores({
        user: 'id,name,email,nif,photo,hashPin,salt,token,expiresIn,privacyPolicyAccepted,privacyDate,companyId -> company.id,category,updateDate',
      })
      .upgrade(async (tx: any) => {
        await tx
          .table('user')
          .toCollection()
          .modify((user: any) => {
            user.updateDate = null;
          });
        return null;
      });

    this.db
      .version(12)
      .stores({
        company: 'id,name,status',
      })
      .upgrade(async (tx: any) => {
        await tx
          .table('company')
          .toCollection()
          .modify((company: any) => {
            company.status = CompanyStatus.APPROVED;
          });
        return null;
      });

    this.db.version(13).stores({
      journey:
        'id,userId -> user.id,companyId -> company.id,vehicles,date,endDate,notes,place,latitude,longitude,pending',
    });

    this.db
      .version(14)
      .stores({
        journey:
          'id,userId -> user.id,companyId -> company.id,vehicles,date,endDate,notes,place,endPlace,latitude,longitude,pending',
      })
      .upgrade(async (tx: any) => {
        await tx
          .table('journey')
          .toCollection()
          .modify((journey: any) => {
            journey.endPlace = null;
          });
        return null;
      });

    this.db
      .version(15)
      .stores({
        journey:
          'id,userId -> user.id,companyId -> company.id,vehicles,date,endDate,notes,endNotes,place,endPlace,latitude,longitude,pending',
      })
      .upgrade(async (tx: any) => {
        await tx
          .table('journey')
          .toCollection()
          .modify((journey: any) => {
            journey.endNotes = '';
          });
        return null;
      });

    this.db
      .version(16)
      .stores({
        tachograph:
          'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending,restType,latitude,longitude,journey,teamDriving,originalId->tachograph.id,originalDate',
      })
      .upgrade((tx: any) => {
        return tx
          .table('tachograph')
          .toCollection()
          .modify((tachograph: any) => {
            tachograph.originalId = tachograph.id;
            tachograph.originalDate = tachograph.date;
            tachograph.pending = true;
          });
      });

    this.db.version(17).stores({
      tachograph:
        'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending,restType,latitude,longitude,journey->journey.id,teamDriving,originalId->tachograph.id,originalDate',
    });

    this.db
      .version(18)
      .stores({
        tachograph:
          'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending,restType,latitude,longitude,journey->journey.id,teamDriving,originalId->tachograph.id,originalDate,sync,device',
      })
      .upgrade((tx: any) => {
        return tx
          .table('tachograph')
          .toCollection()
          .modify((tachograph: any) => {
            tachograph.sync = '';
            tachograph.device = '';
          });
      });

    this.db
      .version(19)
      .stores({
        tachograph:
          'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending,restType,latitude,longitude,journey->journey.id,teamDriving,originalId->tachograph.id,originalDate,sync,device,appVersion',
      })
      .upgrade((tx: any) => {
        return tx
          .table('tachograph')
          .toCollection()
          .modify((tachograph: any) => {
            tachograph.appVersion = '';
          });
      });

    this.db
      .version(20)
      .stores({
        tachograph:
          'id,status,userId -> user.id,companyId -> company.id,vehicleId -> vehicle.id,date,endDate,km,endKm,notes,place,pending,restType,latitude,longitude,journey->journey.id,teamDriving,originalId->tachograph.id,originalDate,sync,device,appVersion,tsCreated,tsUpdated',
      })
      .upgrade((tx: any) => {
        return tx
          .table('tachograph')
          .toCollection()
          .modify((tachograph: any) => {
            tachograph.tsCreated = null;
            tachograph.tsUpdated = null;
          });
      });

      this.db.version(21).stores({
        licenses:
          'id,status,companyId -> company.id,userId -> user.id,from,to',
      });
      this.db
      .version(22)
      .stores({
        company: 'id,name,status,isDemo',
      })
      .upgrade(async (tx: any) => {
        await tx
          .table('company')
          .toCollection()
          .modify((company: any) => {
            company.isDemo = false;
          });
        return null;
      });
      this.db.version(23).stores({
        configurations:
          'label,value',
      });
    this.db.vehicle.toArray().then(async (vehicles: any) => {
      for (const v of vehicles) {
        if (!v.km) {
          await this.db.vehicle.update(v.id, {
            km: 0,
          });
        }
      }
    });

    this.db.open().catch(function (err: any) {
      console.error(err.stack || err);
    });
  }
}
