import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  handleError: any;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  syncDataShared: Subject<boolean> = new Subject<boolean>()

  showLoadingShared: Subject<boolean> = new Subject<boolean>();

  serverDate: any = null;

  syncData(syncData: any) {
    let uri = `${environment.API_URL}/api/sync`;
    return this.http.post<any>(uri, syncData, { headers: this.headers });
  }

  syncCheck(syncData: any) {
    let uri = `${environment.API_URL}/api/syncCheck`;
    return this.http.post<any>(uri, syncData, { headers: this.headers });
  }
}
