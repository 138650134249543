import { Injectable } from '@angular/core';
import { UtilsService } from '../service/utils.service';
import { DatabaseService } from './database.service';

export class SystemConfigurationModel {
  public id!: string;
  public version!: number;
  public text!: string;
  public type!: SystemConfigurationType;
}

export enum SystemConfigurationType {
  PRIVACY = 'PRIVACY',
  HELP_PWA = 'HELP_PWA',
  HELP_BO = 'HELP_BO',
}

@Injectable({
  providedIn: 'root',
})
export class SystemConfigurationService {
  constructor(
    private readonly databaseSrv: DatabaseService,
    private readonly utilsSrv: UtilsService
  ) {}

  getPwaHelp() {
    return this.databaseSrv.db.systemConfiguration
      .where({ type: SystemConfigurationType.HELP_PWA })
      .toArray()
      .then((systemConfigurations: SystemConfigurationModel[]) => {
        if (systemConfigurations.length > 0) return systemConfigurations[0];
        else return null;
      })
      .catch((err: any) => {
        console.log('%csystem-configuration.service.ts line:36 err', 'color: #007acc;', err);
        return null;
      });
  }

  getPrivacyPolicy() {
    return this.databaseSrv.db.systemConfiguration
      .where({ type: SystemConfigurationType.PRIVACY })
      .toArray()
      .then((systemConfigurations: SystemConfigurationModel[]) => {
        if (systemConfigurations.length > 0) return systemConfigurations[0];
        else return null;
      })
      .catch((err: any) => {
        console.log('%csystem-configuration.service.ts line:36 err', 'color: #007acc;', err);
        return null;
      });
  }

  deleteAndInsertSystemConfigurations(
    systemConfigurations: SystemConfigurationModel[]
  ) {
    return this.databaseSrv.db.systemConfiguration
      .where('type')
      .anyOf([
        SystemConfigurationType.PRIVACY,
        SystemConfigurationType.HELP_PWA,
      ])
      .delete()
      .then(() => {
        this.databaseSrv.db.systemConfiguration.bulkPut(systemConfigurations);
      });
  }
}
