<div id="wrapper">
  <nav class="nav-extended topbar">
    <div class="nav-wrapper sidebarButton" (click)="openSidenav()">
      <div class="logo-wrapper">
        <img class="xgo-logo" src="assets/logo/antram-light-logo.png" />
      </div>
      <a class="sidebar-trigger">
        <span class="mdi mdi-menu"></span>
      </a>
    </div>
  </nav>

  <ul #slideOut class="sidenav">
    <li (click)="clickProfile()">
      <div class="user-view">
        <div class="background">
          <img class="menuBackground" src="assets/images/menu-background.jpeg" />
        </div>
        <a><img class="circle userPhoto" [src]="user?.photo" /></a>
        <a><span class="white-text name">{{ user?.name }}</span></a>
        <a><span class="white-text email">{{ user?.email }}</span></a>
      </div>
    </li>
    <li>
      <a class="sidebar-line" (click)="clickHome()"><span class="mdi mdi-home"></span>&nbsp;Início</a>
    </li>
    <li>
      <a class="sidebar-line waves-effect" (click)="clickProfile()"><span class="mdi mdi-account"></span>&nbsp;Perfil de
        Utilizador</a>
    </li>
    <!-- <li>
      <a
        class="sidebar-line waves-effect"
        (click)="clickChangeCompany()"
        *ngIf="user?.userCompanies && (user?.userCompanies)!.length > 1"
        ><span class="mdi mdi-office-building"></span>&nbsp;Alterar Empresa</a
      >
    </li> -->
    <li>
      <a class="sidebar-line waves-effect" (click)="clickTacographHistory()"><span
          class="mdi mdi-history"></span>&nbsp;Histórico de Registos</a>
    </li>
    <li>
      <a class="sidebar-line waves-effect" (click)="clickHelpPage()"><span
          class="mdi mdi-help-circle"></span>&nbsp;Ajuda</a>
    </li>
    <li>
      <a class="sidebar-line waves-effect" (click)="clickPrivacyPolicy()"><span
          class="mdi mdi-script-text"></span>&nbsp;Política de
        Privacidade</a>
    </li>
    <!-- <li>
      <a class="sidebar-line waves-effect" (click)="clickExport()"
        ><span class="mdi mdi-database-export"></span>&nbsp;Exportar Registos</a
      >
    </li> -->
    <li>
      <div class="divider"></div>
    </li>
    <li>
      <a class="sidebar-line waves-effect" (click)="clickLogout()"><span class="mdi mdi-logout"></span>&nbsp;Terminar
        Sessão</a>
    </li>
    <div class="rittVersion">
      <div>RITT v.{{ version }}</div>
      <div>Desenvolvido por XpertGo</div>
    </div>
  </ul>
</div>
<div class="content">
  <router-outlet></router-outlet>
</div>

<ng-template #customLoadingTemplate>
  <div *ngIf="showLoadingMessage" class="custom-class">
    <p>A atualizar dispositivo. Aguarde!</p>
  </div>
</ng-template>

<ngx-loading [show]="syncLoading" class="loading" [template]="customLoadingTemplate"></ngx-loading>


<div class="installBanner" *ngIf="showInstallBanner">
  <div class="app-logo-container">
    <img class="img-logo" src="assets/logo/xGo_logo.jpg" />
    <span class="install-text">Instalar Aplicação no Dispositivo?</span>
  </div>
  <div class="install-button-container">
    <a class="waves-effect waves-light btn" (click)="installPwa(true)">Instalar</a>
    <a class="not-now-button" (click)="installPwa(false)">Agora não</a>
  </div>
</div>

<!-- Modal Structure -->
<div #modal class="modal modal-fixed-footer">
  <div class="modal-content">
    <h4 style="font-weight: bold; font-size: 20px;">Registos inválidos!</h4>
    <p style="font-size: 13px;">Os registos indicados são inválidos pois enquadram-se numa das seguintes situações:</p>
    <ol style="font-size: 12px;">
      <li>Existem registos com data posterior</li>
      <li>Registo anterior com o mesmo estado</li>
      <li>Os registos encontram-se fora do período do serviço</li>
    </ol>
    <table class="table-error" style="font-size: 12px;">
      <thead>
        <tr>
          <th>Data Ínicio</th>
          <th>Estado</th>
          <th>Veículo</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tachograph of inconsistentTachographs">
          <td style="width: 80px;">{{utilsSrv.dateTimeToString(tachograph.date)}}</td>
          <td>{{tachographSrv.getImgAndDescription(tachograph.status).description}}</td>
          <td>
            <span
              *ngIf="tachograph.status == 'CONDUCAO' || (tachograph.status == 'INICIO' && (tachograph?.vehicleId || (tachograph?.licensePlate && tachograph?.licensePlate != '')))">
              {{ tachograph?.licensePlate }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <a (click)="closeModal()" class="modal-close waves-effect waves-green btn-flat">OK</a>
  </div>
</div>

<!-- Modal isDemo Structure -->
<div #modalDemo class="modal modal-fixed-footer">
  <div class="modal-content">
    <h4 style="font-weight: bold; font-size: 20px;">A aplicação foi licenciada.</h4>
    <p style="font-size: 13px;">Todos os dados registados no modo de demonstração serão eliminados.</p>
  </div>
  <div class="modal-footer">
    <a (click)="closeIsDemoModal()" class="modal-close waves-effect waves-green btn-flat">OK</a>
  </div>
</div>