import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { AuthService } from '../service/auth.service';
import { filter, first, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const token = localStorage.getItem('token');
    const expiresIn = localStorage.getItem('expiresIn');

    if (request.headers.has('no-token') || request.url.includes('.json') || request.headers.has('Authorization') ) {
      return next.handle(request);
    }

    if (token && moment().isBefore(moment().add(expiresIn))) {
      const cloned = request.clone({
        headers: request.headers.append('Authorization', `Bearer ${token}`),
      });
      return next.handle(cloned);
    } else {
      M.toast({
        html: 'Credenciais de autenticação expiradas.',
      });
      this.router.navigate(['/auth/auth-home']);
      return next.handle(request);
    }
  }
}
