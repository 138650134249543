import { Injectable } from '@angular/core';
import { UserModel } from './user.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UtilsService } from '../service/utils.service';
import { CompanyModel } from './company.service';
import { DatabaseService } from './database.service';
import { VehicleService } from './vehicle.service';

export class JourneyModel {
  public id: string;
  public userId: string;
  public user: UserModel;
  public companyId: string;
  public company: CompanyModel;
  public vehicles: any[];
  public date: Date;
  public endDate: Date | null;
  public notes: string;
  public endNotes: string;
  public place: string;
  public endPlace: string;
  public latitude: string | null;
  public longitude: string | null;
  public pending: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class JourneyService {
  handleError: any;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private readonly databaseSrv: DatabaseService,
    private readonly utilsSrv: UtilsService,
    private readonly vehicleSrv: VehicleService,
    private http: HttpClient
  ) { }

  get(id: string): JourneyModel {
    return this.databaseSrv.db.journey
      .where({ id })
      .with({ company: 'companyId' })
      .then((res: JourneyModel[]) => {
        if (res.length > 0) {
          return res[0];
        } else return null;
      });
  }

  getCurrent(userId: string, companyId: any = null): JourneyModel {
    return this.databaseSrv.db.journey
      .where({
        userId: userId,
        companyId: companyId,
      })
      .with({})
      // .reverse()
      // .sortBy('date')
      .then((res: JourneyModel[]) => {
        res = res.filter((r: JourneyModel) => r.endDate == null);
        if (res.length > 0) {
          const r = this.utilsSrv.sortArrayByDate(res, 'date', -1);
          // console.log("getCurrent: ", r[0].id);
          return r[0];
        } else {
          console.log("getCurrent: null");
          return null;
        }
      });
  }

  getLast(userId: string, companyId: any = null): JourneyModel {
    return (
      this.databaseSrv.db.journey
        .where({
          userId: userId,
          companyId: companyId,
        })
        // .reverse()
        // .sortBy('date')
        .with({ company: 'companyId' })
        .then((res: JourneyModel[]) => {
          if (res.length > 0) {
            const r = this.utilsSrv.sortArrayByDate(res, 'date', -1);
            return r[0];
          } else return null;
        })
    );
  }

  getOpenJourneys(userId: string): JourneyModel[] {
    return this.databaseSrv.db.journey
      .where({
        userId,
      })
      .with({ company: 'companyId' })
      .then((res: JourneyModel[]) => {
        if (res.length > 0) {
          const r = this.utilsSrv.sortArrayByDate(
            res.filter((r) => r.endDate == null),
            'date',
            -1
          );
          return r;
        } else return [];
      });
  }

  async save(userId: string, data: JourneyModel) {
    return this.databaseSrv.db
      .transaction('rw', this.databaseSrv.db.journey, () => {
        return this.databaseSrv.db.journey.add(data);
      })
      .catch((err: any) => {
        console.log('%journey.service.ts line:109 err', 'color: #007acc;', err);
      });
  }

  async update(data: JourneyModel): Promise<boolean> {
    data.pending = true;
    await this.databaseSrv.db.journey.update(data.id, data);
    return true;
  }

  getPending(userId: string): any[] {
    return this.databaseSrv.db.journey
      .where({ userId })
      .toArray()
      .then((journeys: JourneyModel[]) => {
        return journeys
          .filter((j) => j.pending == true)
          .map((journey) => {
            return {
              id: journey.id,
              userId: journey.userId,
              companyId: journey.companyId,
              vehicles: JSON.stringify(journey.vehicles),
              date: journey.date ? journey.date.toISOString() : null,
              endDate: journey.endDate ? journey.endDate.toISOString() : null,
              notes: journey.notes,
              endNotes: journey.endNotes,
              place: journey.place,
              endPlace: journey.endPlace,
              latitude: journey.latitude,
              longitude: journey.longitude,
            };
          });
      })
      .catch((err: any) => {
        console.log(
          '%cjourney.service.ts line:271 err',
          'color: #007acc;',
          err
        );
        return null;
      });
  }

  getPendingJourney(journeyId: string) {
    return this.databaseSrv.db.journey
      .where({ id: journeyId })
      .with({ company: 'companyId' })
      .then((res: JourneyModel[]) => {
        if (res?.length > 0 && res?.[0]?.pending) {
          return {
            id: res[0].id,
            userId: res[0].userId,
            companyId: res[0].companyId,
            vehicles: JSON.stringify(res[0].vehicles),
            date: res[0].date ? res[0].date.toISOString() : null,
            endDate: res[0].endDate ? res[0].endDate.toISOString() : null,
            notes: res[0].notes,
            endNotes: res[0].endNotes,
            place: res[0].place,
            endPlace: res[0].endPlace,
            latitude: res[0].latitude,
            longitude: res[0].longitude,
          };
        } else {
          return null;
        }
      });
  }

  async updateOrCreate(journey: JourneyModel) {
    const journeyAux = await this.get(journey.id);
    if (journeyAux) {
      await this.databaseSrv.db.journey.update(journey.id, journey);
    } else {
      await this.databaseSrv.db.journey.add(journey);
    }
  }

  deleteJourney(id: string) {
    return this.databaseSrv.db.journey
      .where({ id })
      .delete();
  }
  deleteJourneyByUserAndCompany(userId: string, companyId: string) {
    return this.databaseSrv.db.journey
      .where({ userId, companyId })
      .delete();
  }
}
