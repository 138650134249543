import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';

export enum CompanyStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DELETED = 'DELETED',
}

export class CompanyModel {
  public id!: string;
  public name!: string;
  public status!: CompanyStatus;
  public isDemo!: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private readonly databaseSrv: DatabaseService) {}

  getCompanyById(comapnyId: string): any {
    return this.databaseSrv.db.company
      .where({ id: comapnyId })
      .toArray()
      .then((companies: CompanyModel[]) => {
        if (companies.length > 0) return companies[0];
        else return null;
      })
      .catch((err: any) => {
        console.log('%cvehicle.service.ts line:77 err', 'color: #007acc;', err);
        return null;
      });
  }

  async updateOrCreateCompany(company: CompanyModel) {
    const companyAux = await this.getCompanyById(company.id);
    if (companyAux) {
      await this.databaseSrv.db.company.update(company.id, company);
    } else {
      await this.databaseSrv.db.company.add(company);
    }
  }
}
