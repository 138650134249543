import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseSeedsService {
  runSeeds(db: any) {
    db.transaction(
      'rw',
      db.company,
      db.user,
      db.user,
      db.tachograph,
      () => {
        // COMPANIES
        db.company.bulkPut([
          {
            id: 'cc72e672-63fc-4ce0-855e-64fbe5389fd2',
            name: 'XpertGo',
          },
          {
            id: 'b50e540b-f3b5-474b-98be-6572cbf4e479',
            name: 'XGo',
          },
          {
            id: 'd8864b6d-5448-4864-97f4-89a10a631a94',
            name: 'Antram',
          },
        ]);

        //USERS
        db.user.bulkPut([
          {
            id: '3f517ee2-e8ba-4fd0-95bb-76fcdf53587c',
            name: 'José Rui',
            email: 'jose.rui@xpertgo.pt',
            companyId: 'cc72e672-63fc-4ce0-855e-64fbe5389fd2',
            password: 'test',
            pin: 1234,
            salt: '',
            photo:
              'https://static.vecteezy.com/system/resources/previews/002/275/847/large_2x/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg',
          },
          {
            id: 'dc54a237-5034-4bf5-b144-4e98be13986c',
            name: 'Teófilo Armando',
            email: 'teofilo.armando@antram.pt',
            password: 'test',
            companyId: 'd8864b6d-5448-4864-97f4-89a10a631a94',
            pin: 1234,
            salt: '1234',
            photo: null,
          },
          {
            id: '9de46b93-d049-4a6f-80bf-9bd9c861dc7e',
            name: 'Leonardo Sá',
            email: 'leonardo.sa@xgo.pt',
            companyId: 'b50e540b-f3b5-474b-98be-6572cbf4e479',
            password: 'test',
            pin: 1234,
            salt: '',
            photo:
              'https://media.gettyimages.com/id/464967684/pt/foto/leonard-nimoy-as-mr-spock-in-star-trek-the-original-series-episode-amok-time-spock-shows-the.jpg?s=2048x2048&w=gi&k=20&c=915XNUfYaHl_GQrxGWIw-qBIrZkdgk8uao-fdwpKMWQ=',
          },
        ]);
      }
    );
  }
}
