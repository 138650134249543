import { Injectable } from '@angular/core';
import { CompanyModel } from './company.service';
import { DatabaseService } from './database.service';

export class UserCompanyModel {
  public id!: string;
  public userId!: string;
  public companyId!: string;
  public company?: CompanyModel;
  public pending?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserCompaniesService {
  constructor(
    private readonly databaseSrv: DatabaseService
  ) {
  }

  getUserCompanies(userId: string): CompanyModel[] {
    return this.databaseSrv.db.userCompanies
      .where({ userId })
      .with({ company: 'companyId' })
      .then((res: UserCompanyModel[]) => {
        return res.map((r: UserCompanyModel) => r.company);
      });
  }

  getUserCompany(userId: string, companyId: string): UserCompanyModel | null {
    return this.databaseSrv.db.userCompanies
      .where({ userId, companyId })
      .with({ company: 'companyId' })
      .then((res: UserCompanyModel[]) => {
        if (res.length > 0) {
          return res[0];
        } else return null;
      });
  }

  deleteAndInsertCompanies(userId: string, companies: any[]) {
    return this.databaseSrv.db.userCompanies
      .where({ userId })
      .delete()
      .then(() => {
        this.databaseSrv.db.userCompanies.bulkPut(companies);
      });
  }

  async setActiveCompany(userId: string, companyId: string) {
    const userCompanies = await this.databaseSrv.db.userCompanies.where({ userId }).toArray();
    const idsToDelete = userCompanies.filter((uc: any) => uc.companyId != companyId).map((uc: any) => { return uc.id })
    return this.databaseSrv.db.userCompanies.where('id').anyOf(idsToDelete).delete().then((res: any) => {
      return res;
    })
  }
}
