import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserModel, UserService } from '../database/user.service';
import { UtilsService } from './utils.service';
import * as bcrypt from 'bcryptjs';
import { UserCompaniesService } from '../database/user-companies.service';
import { CompanyService } from '../database/company.service';
// import * as M from 'materialize-css';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  handleError: any;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  user: UserModel | null = null;

  constructor(
    private readonly userSrv: UserService,
    private router: Router,
    private readonly utilsSrv: UtilsService,
    private readonly companySrv: CompanyService,
    private readonly userCompaniesSrv: UserCompaniesService,
    private http: HttpClient
  ) {}

  public currentUser(): UserModel | null {
    if (!this.user) {
      this.user = JSON.parse(sessionStorage.getItem('currentUser')!);
    }
    
    return this.user;
  }

  isLoggedIn() {
    const token = localStorage.getItem('token'); // get token from local storage
    if (!token) return false;

    const payload = atob(token.split('.')[1]); // decode payload of token
    const parsedPayload = JSON.parse(payload); // convert payload into an Object

    return parsedPayload.exp > Date.now() / 1000; // check if token is expired
  }

  login(email: string, password: string): any {
    let uri = '';
    let authData = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      uri = `${environment.API_URL}/api/auth/login`;
      authData = {
        email,
        password,
      };
    }

    const vatRegex = /^\d{9}$/;
    if (vatRegex.test(email)) {
      uri = `${environment.API_URL}/api/auth/nif-login`;
      authData = {
        nif: email,
        pin: password,
      };
    }

    if (uri == '') {
      M.toast({ html: 'Email/NIF Inválidos' });
      return null;
    }

    if (!this.utilsSrv.isOnline()) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      return new Promise((resolve, reject) => {
        this.http
          .post<any>(uri, authData, {
            headers: this.headers.append('no-token', ''),
          })
          .subscribe(
            async (res: any) => {
              if (res.data.length > 0) {
                localStorage.setItem('token', res.data[0].accessToken);
                localStorage.setItem('expiresIn', res.data[0].expiresIn);

                // if (res.data[0].payload.role != 'USER') {
                //   M.toast({ html: 'O utilizador não tem acesso à aplicação' });
                //   return null;
                // }
                if (res.data[0].payload.companyUsers.length == 0) {
                  M.toast({
                    html: 'O utilizador não tem nenhuma empresa associada',
                  });
                  resolve(null);
                }
                this.setCurrentUser(res.data[0].payload);

                for (const cu of res.data[0].payload.companyUsers) {
                  await this.companySrv.updateOrCreateCompany({
                    id: cu.company.id,
                    name: cu.company.name,
                    status: cu.company.status,
                    isDemo:cu.isDemo
                  });
                }

                await this.userCompaniesSrv.deleteAndInsertCompanies(
                  res.data[0].payload.id,
                  res.data[0].payload.companyUsers
                );

                const userCompanies =
                  await this.userCompaniesSrv.getUserCompanies(
                    res.data[0].payload.id
                  );

                if (userCompanies.length > 1) {
                  resolve(this.router.navigate(['select-company']));
                } else {
                  localStorage.setItem(
                    'currentCompany',
                    JSON.stringify(userCompanies[0])
                  );
                  resolve(this.router.navigate(['home']));
                }
              } else {
                M.toast({ html: 'Login inválido' });
                resolve(null);
              }
            },
            (error: any) => {
              // M.toast({ html: error.error.error });
              if (error.error?.errors?.length > 0) {
                this.utilsSrv.toast(error.error?.errors[0].error);
                if (error.error?.errors[0].message == 'AUTH_ACTIVATE_NIF_LOGIN') {
                  resolve(error.error?.errors[0].message);
                } else {
                  resolve(null);
                }
              } else {
                this.utilsSrv.toast(
                  'Ocorreu um erro na autenticação, por favor tente outra vez.'
                );
                resolve(null);
              }
            }
          );
      })
    }
  }

  validateAccount(code: string, password: string, pin: string) {
    let uri = `${environment.API_URL}/api/auth/verification`;

    const data = {
      code,
      password,
      pin,
    };

    if (!this.utilsSrv.isOnline()) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      return this.http.post<any>(uri, data, {
        headers: this.headers.append('no-token', ''),
      });
    }
  }

  setCurrentUser(userPayload: any) {
    if (
      !userPayload.photo ||
      userPayload.photo == null ||
      userPayload.photo.trim == ''
    ) {
      userPayload['photo'] = this.utilsSrv.getInitialsAvatar(userPayload.name);
    }    
    localStorage.setItem('currentUser', JSON.stringify(userPayload));

    this.userSrv.updateOrCreateUser(userPayload);
  }

  pinVerification(nif: string, code: string, pin: string) {
    let uri = `${environment.API_URL}/api/auth/pinVerification`;

    const data = {
      nif,
      activationPin: code,
      pin,
    };

    if (!this.utilsSrv.isOnline()) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      return this.http.post<any>(uri, data, {
        headers: this.headers.append('no-token', ''),
      });
    }
  }

  async pinLogin(userId: string, pin: string) {
    const user = await this.userSrv.getStoredUser(userId);

    if (!user) {
      return;
    }

    const salt = user.salt!;
    const hashed = await bcrypt.hash(`${pin}${userId}`, salt);

    if (user.hashPin === hashed) {
      localStorage.setItem('token', user.token!);
      localStorage.setItem('currentUser', JSON.stringify(user));

      const userCompanies = await this.userCompaniesSrv.getUserCompanies(
        userId
      );

      if (!userCompanies || userCompanies.length == 0) {
        M.toast({ html: 'Utilizador não tem nenhuma empresa associada' });
        return null;
      } else {
        if (userCompanies.length > 1) {
          return this.router.navigate(['select-company']);
        } else {
          localStorage.setItem(
            'currentCompany',
            JSON.stringify(userCompanies[0])
          );
          return this.router.navigate(['home']);
        }
      }
    } else {
      M.toast({ html: 'Pin inválido' });
      return null;
    }
  }

  passwordRecovery(email: string) {
    let uri = `${environment.API_URL}/api/auth/passwordRecovery`;

    const data = {
      email,
    };

    if (!this.utilsSrv.isOnline()) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      return this.http.post<any>(uri, data, {
        headers: this.headers.append('no-token', ''),
      });
    }
  }

  resetPassword(code: string, password: string) {
    let uri = `${environment.API_URL}/api/auth/passwordReset`;

    const data = {
      code,
      password,
    };

    if (!this.utilsSrv.isOnline()) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      return this.http.post<any>(uri, data, {
        headers: this.headers.append('no-token', ''),
      });
    }
  }

  pinRecovery(email: string) {
    let uri = `${environment.API_URL}/api/auth/pinRecovery`;

    const data = {
      email,
    };

    if (!this.utilsSrv.isOnline()) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      return this.http.post<any>(uri, data, {
        headers: this.headers.append('no-token', ''),
      });
    }
  }

  resetPin(code: string, pin: string) {
    let uri = `${environment.API_URL}/api/auth/pinReset`;

    const data = {
      code,
      pin,
    };

    if (!this.utilsSrv.isOnline()) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      return this.http.post<any>(uri, data, {
        headers: this.headers.append('no-token', ''),
      });
    }
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentCompany');
    localStorage.removeItem('token');
    this.user = null;
    return this.router.navigate(['auth/auth-home']);
  }
}
