import { Injectable } from '@angular/core';
import Avatar from 'avatar-initials';
import { CompanyModel } from '../database/company.service';
import { UserModel } from '../database/user.service';
import { v4 as uuidv4 } from 'uuid';
import { OfflineService } from '../database/offline.service';
import { JourneyModel } from '../database/journey.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private offlineSrv: OfflineService) { }

  passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{9,})/;
  pinRegex = /^(^\d{4}$)/;
  pinNIFRegex = /^(^\d{6}$)/;

  getInitialsAvatar(name: string = '') {
    if (name == '') this.getCurrentUser()?.name;
    return Avatar.initialAvatar({
      size: 160,
      initials: name
        .split(' ')
        .map((n: string) => n.substring(0, 1))
        .join('')
        .toUpperCase(),
      initial_fg: '#2a495a',
      initial_bg: '#97a6ae',
      initial_size: 50,
      initial_weight: 100,
      initial_font_family: "'Lato', 'Lato-Regular', 'Helvetica Neue'",
    });
  }

  getCurrentUser(): UserModel | null {
    const user = localStorage.getItem('currentUser');
    if (user) return JSON.parse(user);
    return null;
  }

  getCurrentCompany(): CompanyModel | null {
    const company = localStorage.getItem('currentCompany');
    if (company) return JSON.parse(company);
    return null;
  }

  getCurrentService(): JourneyModel | null {
    const service = localStorage.getItem('currentService');
    if (service) return JSON.parse(service);
    return null;
  }

  getIsFirstTachographFromCurrentService(): boolean {
    const service: any = localStorage.getItem('currentService');
    if (service) {
      const vehicles = service?.vehicles
        ? JSON.parse(service?.vehicles)
        : [];
      return vehicles && vehicles?.length === 0 || !vehicles ? true : false;
    } else return true;
  }

  iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  dateDiffToString(a: any, b: any) {
    const dateA = new Date(a);
    const dateB = new Date(b);
    let diff = Math.abs(dateA.getTime() - dateB.getTime());

    let ms = diff % 1000;
    diff = (diff - ms) / 1000;
    let s = diff % 60;
    diff = (diff - s) / 60;
    let m = diff % 60;
    diff = (diff - m) / 60;
    let h = diff;

    let ss = s <= 9 && s >= 0 ? `0${s}` : s;
    let mm = m <= 9 && m >= 0 ? `0${m}` : m;
    let hh = h <= 9 && h >= 0 ? `0${h}` : h;

    return hh + ':' + mm + ':' + ss;
  }

  dateTimeToString(dt: any) {
    const date = new Date(dt);
    var dateStr =
      ('00' + date.getDate()).slice(-2) +
      '/' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      date.getFullYear() +
      ' ' +
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2) +
      ':' +
      ('00' + date.getSeconds()).slice(-2);

    return dateStr;
  }

  dateTimeToOnlyDateString(date: Date) {
    var dateStr =
      ('00' + date.getDate()).slice(-2) +
      '/' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      date.getFullYear();

    return dateStr;
  }

  dateTimeToTimeString(dt: any, seconds: boolean = false) {
    const date = new Date(dt);
    return (
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2) +
      (seconds == true ? ':' + ('00' + date.getSeconds()).slice(-2) : '')
    );
  }

  sortArrayByProperty(array: any[], property: string, mode: number): any[] {
    const sorted = array.sort((a, b) =>
      a[property] > b[property] ? 1 : b[property] > a[property] ? -1 : 0
    );
    if (mode > 0) {
      return sorted;
    } else {
      return sorted.reverse();
    }
  }

  sortArrayByDate(array: any[], property: string, mode: number): any[] {
    const sorted = array.sort((a, b) => a[property] - b[property]);
    if (mode > 0) {
      return sorted;
    } else {
      return sorted.reverse();
    }
  }

  getUUID(): string {
    return uuidv4();
  }

  isMobile(): boolean {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  }

  jsonToCsv(user: UserModel, data: any[]) {
    const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row: any) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    if (user) {
      const date = new Date();
      a.download = `${user.name}_${this.dateTimeToOnlyDateString(
        date
      )}_${this.dateTimeToTimeString(date)}`;
    } else {
      a.download = 'myFile.csv';
    }
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  isOnline(): boolean {
    return this.offlineSrv.isOnline;
  }

  toast(message: string) {
    M.toast({
      html: message,
    });
  }
}
