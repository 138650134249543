import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { DatabaseService } from './core/database/database.service';
import { UtilsService } from './core/service/utils.service';
import * as M from 'materialize-css';
import { environment } from 'src/environments/environment';
import { VersionCheckService } from './shared/services/version-check.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  host: {
    '(window:resize)': 'onResize($event)',
  },
})
export class AppComponent implements AfterViewInit {
  title = 'RITT-PWA';

  constructor(
    private readonly databaseSrv: DatabaseService,
    private readonly utilsSrv: UtilsService,
    public versionCheckService: VersionCheckService,
    private swUpdate: SwUpdate,
  ) {

    /*if(this.swUpdate.isEnabled) {
      console.log("SW activated");
      this.swUpdate.checkForUpdate().then(newUpdate => {
        if(newUpdate) {
          console.log("New update found");
          this.swUpdate.activateUpdate().then((updated) => {
            if(!updated) {
              console.log("Updated by sw");
              document.location.reload()
            } else {
              console.log("not Updated by sw");
              document.location.reload()
            }
            });
        } else {
          console.log("Current version");
        }
      });
    }*/
    // CHECK VERSION
    versionCheckService.initVersionCheck(environment.version_check_url);
    
    // if (!this.utilsSrv.iOS) {
    //   if (Notification.permission == 'granted') {
    //     navigator.serviceWorker.getRegistration().then((reg) => {
    //       reg?.showNotification('Teste notificação', {});
    //     });
    //   } else {
    //     Notification.requestPermission((status) => {
    //       alert(status);
    //     });
    //   }
    // }
  }

  ngAfterViewInit(): void {
    this.fixScrollHeight();

    const deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      localStorage.setItem('deviceId', this.utilsSrv.getUUID());
    }
  }

  onResize() {
    this.fixScrollHeight();
  }

  fixScrollHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
