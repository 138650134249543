import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../service/utils.service';
import { CompanyModel } from './company.service';
import { DatabaseService } from './database.service';
import { UserCompaniesService } from './user-companies.service';

export class UserModel {
  public id!: string;
  public name!: string;
  public category!: string;
  public email!: string;
  public nif!: string;
  public photo?: string;
  public contact?: string;
  public birthDate?: Date;
  public address?: string;
  public postalCode?: string;
  public hashPin?: string;
  public salt?: string;
  public companyUsers!: CompanyModel[];
  public token!: string;
  public expiresIn!: Date;
  public privacyPolicyAccepted!: boolean;
  public privacyDate!: Date;
  public companyId?: string;
  public company?: CompanyModel;
  public updateDate?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private readonly userCompanySrv: UserCompaniesService,
    private readonly databaseSrv: DatabaseService,
    private http: HttpClient,
    private utilsSrv: UtilsService,
    private router: Router
  ) {}

  setActiveCompany(companyId: string) {
    if (!this.utilsSrv.isOnline) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      let uri = `${environment.API_URL}/api/user/activeCompany`;
      const data = {
        companyId,
      };
      return this.http
        .patch<any>(uri, data, { headers: this.headers })
        .subscribe(
          (res: any) => {
            // if (res.data.length > 0) {
            //   if (res.data[0].companyUsers.length == 1) {
            //     localStorage.setItem(
            //       'currentCompany',
            //       JSON.stringify(res.data[0].companyUsers[0])
            //     );
            //     this.router.navigate(['home']);
            //   }
            // }
            return res;
          },
          (error: any) => {
            M.toast({ html: error.error.error });
          }
        );
    }
  }

  acceptPrivacyPolicy(userId: string) {
    return this.databaseSrv.db.user
      .update(userId, {
        privacyPolicyAccepted: true,
        privacyDate: new Date(),
      })
      .then(async (updated: any) => {
        return updated;
      });
  }

  changePassword(password: string) {
    if (!this.utilsSrv.isOnline) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      let uri = `${environment.API_URL}/api/user/password`;
      const data = {
        password,
      };
      return this.http.patch<any>(uri, data, { headers: this.headers });
    }
  }

  changePin(pin: string) {
    if (!this.utilsSrv.isOnline) {
      M.toast({ html: 'Não existe uma conexão à internet' });
      return null;
    } else {
      let uri = `${environment.API_URL}/api/user/pin`;
      const data = {
        pin,
      };
      return this.http.patch<any>(uri, data, { headers: this.headers });
    }
  }

  getStoredUsers(): UserModel[] {
    return this.databaseSrv.db.user.with({}).then(async (res: UserModel[]) => {
      // for (const r of res) {
      //   r.userCompanies = await this.userCompanySrv.getUserCompanies(r.id);
      // }
      return res;
    });
  }

  removeStoredUser(userId: string): Promise<any> {
    return this.databaseSrv.db.user
      .where({ id: userId })
      .delete()
      .then(() => {
        return this.databaseSrv.db.update
          .where({ userId: userId })
          .delete()
          .then(() => {
            return this.databaseSrv.db.tachograph
              .where({ userId: userId })
              .delete()
              .then(() => {
                return {
                  status: 'SUCCESS',
                };
              });
          });
      });
  }

  getStoredUser(userId: string): UserModel {
    return this.databaseSrv.db.user
      .where({ id: userId })
      .with({})
      .then(
        async (user: UserModel[]) => {
          if (user.length > 0) {
            // user[0].userCompanies = await this.userCompanySrv.getUserCompanies(userId);
            return user[0];
          }
          return null;
        },
        (error: any) => {
          console.log(
            '%cuser.service.ts line:48 error',
            'color: #007acc;',
            error
          );
        }
      );
  }

  async updateOrCreateUser(user: UserModel) {
    return this.databaseSrv.db.user
      .update(user.id, user)
      .then(async (updated: any) => {
        if (!updated) {
          return await this.databaseSrv.db.user.add(user);
        }
      });
  }

  async updateUser(userId: string, user: any) {
    if (!user.photo || user.photo == null || user.photo.trim == '') {
      user['photo'] = this.utilsSrv.getInitialsAvatar(user.name);
    }

    return this.databaseSrv.db.user.update(userId, user);
  }

  updateOnlineUserInfo(token: string) {
    let uri = `${environment.API_URL}/api/user/profile`;

    let params = new HttpParams().appendAll({
      relations: 'companyUsers',
    });

    return this.http.get<any[]>(uri, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}
