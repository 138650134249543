import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../service/utils.service';
import { CompanyModel } from './company.service';
import { DatabaseService } from './database.service';
import { UserModel } from './user.service';
import { VehicleModel, VehicleService } from './vehicle.service';
import { JourneyService } from './journey.service';

export class TachographModel {
  public id!: string;
  public status!: TachographStatus;
  public userId!: string;
  public user?: UserModel;
  public companyId!: string;
  public company?: CompanyModel;
  public vehicleId!: string | null;
  public otherVehicle: boolean;
  public licensePlate: string | null;
  public restType: RestType | null;
  public vehicle?: VehicleModel;
  public date!: Date;
  public endDate!: Date | null;
  public km!: number | null;
  public endKm!: number | null;
  public notes!: string;
  public place!: string;
  public pending?: boolean;
  public latitude?: string | null;
  public longitude?: string | null;
  public journey: string | null;
  public teamDriving: boolean;
  public originalId: string;
  public original?: TachographModel;
  public originalDate: Date;
  public sync?: string;
  public device?: string;
  public appVersion?: string;
  public tsCreated?: Date;
  public tsUpdated?: Date;
}

export enum TachographStatus {
  AUSENCIA = 'AUSENCIA',
  CONDUCAO = 'CONDUCAO',
  DESCANSO = 'DESCANSO',
  DISPONIBILIDADE = 'DISPONIBILIDADE',
  DISPONIBILIDADE_2 = 'DISPONIBILIDADE_2',
  OUTRO_EMPREGADOR = 'OUTRO_EMPREGADOR',
  TRABALHO = 'TRABALHO',
  TROCA_VEICULO = 'TROCA_VEICULO',
  INICIO = 'INICIO',
  FIM = 'FIM',
  NR = 'NR',
}

export enum RestType {
  BREAK = 'BREAK',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  VACATION = 'VACATION',
  ABSENCE = 'ABSENCE',
  OTHER = 'OTHER',
}

@Injectable({
  providedIn: 'root',
})
export class TachographService {
  handleError: any;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private readonly databaseSrv: DatabaseService,
    private readonly utilsSrv: UtilsService,
    private readonly vehicleSrv: VehicleService,
    private readonly journeySrv: JourneyService,
    private http: HttpClient
  ) { }

  getImgAndDescription(type: TachographStatus): {
    img: string;
    description: string;
  } {
    const res = { description: '', img: '' };
    switch (type) {
      case TachographStatus.AUSENCIA:
        res.description = 'Ausência';
        res.img = 'assets/pictograms/ausencia.svg';
        break;
      case TachographStatus.CONDUCAO:
        res.description = 'Condução';
        res.img = 'assets/pictograms/conducao.svg';
        break;
      case TachographStatus.DESCANSO:
        res.description = 'Descanso';
        res.img = 'assets/pictograms/descanso.svg';
        break;
      case TachographStatus.DISPONIBILIDADE:
        res.description = 'Disponibilidade';
        res.img = 'assets/pictograms/disponibilidade.svg';
        break;
      case TachographStatus.DISPONIBILIDADE_2:
        res.description = 'Disponibilidade (Condução em Equipa)';
        res.img = 'assets/pictograms/disponibilidade_2.svg';
        break;
      case TachographStatus.OUTRO_EMPREGADOR:
        res.description = 'Tempo Prestado a Outro Empregador';
        res.img = 'assets/pictograms/outro_empregador.svg';
        break;
      case TachographStatus.TRABALHO:
        res.description = 'Outros Trabalhos';
        res.img = 'assets/pictograms/trabalho.svg';
        break;
      case TachographStatus.TROCA_VEICULO:
        res.description = 'Troca de Veículo';
        res.img = 'assets/pictograms/troca_veiculo.svg';
        break;
      case TachographStatus.INICIO:
        res.description = 'Início de Serviço';
        res.img = 'assets/pictograms/start.svg';
        break;
      case TachographStatus.FIM:
        res.description = 'Fim de Serviço';
        res.img = 'assets/pictograms/stop.svg';
        break;
      case TachographStatus.NR:
        res.description = 'Não Registado';
        res.img = 'assets/pictograms/nr.svg';
        break;
    }
    return res;
  }

  getRestType(restType: RestType): { id: string; description: string } {
    const res = { id: restType.toString(), description: '' };
    res.id = restType;
    switch (restType) {
      case RestType.BREAK:
        res.description = 'Intervalo';
        break;
      case RestType.DAILY:
        res.description = 'Diário';
        break;
      case RestType.WEEKLY:
        res.description = 'Semanal';
        break;
      case RestType.VACATION:
        res.description = 'Férias';
        break;
      case RestType.ABSENCE:
        res.description = 'Falta';
        break;
      case RestType.OTHER:
        res.description = 'Outro';
        break;
    }
    return res;
  }

  getTachograph(id: string): TachographModel {
    return this.databaseSrv.db.tachograph
      .where({ id })
      .with({ company: 'companyId', vehicle: 'vehicleId' })
      .then((res: TachographModel[]) => {
        if (res.length > 0) {
          return res[0];
        } else return null;
      });
  }

  getEndTachographByJourneyId(journeyId: string): TachographModel {
    return this.databaseSrv.db.tachograph
      .where({ journey: journeyId, status: TachographStatus.FIM })
      .with({ company: 'companyId', vehicle: 'vehicleId' })
      .then((res: TachographModel[]) => {
        if (res.length > 0) {
          const r = this.utilsSrv.sortArrayByDate(res, 'date', -1);
          return r[0];
        } else return null;
      });
  }

  getTachographByJourneyId(journeyId: string): TachographModel[] {
    return this.databaseSrv.db.tachograph
      .where({ journey: journeyId })
      .with({ company: 'companyId', vehicle: 'vehicleId' })
      .then((res: TachographModel[]) => {
        if (res.length > 0) {
          const r = this.utilsSrv.sortArrayByDate(res, 'date', -1);
          return r;
        } else return [];
      });
  }

  getTachographWithVehicleByJourneyId(journeyId: string): TachographModel[] {
    return this.databaseSrv.db.tachograph
      .where({ journey: journeyId })
      .with({ company: 'companyId', vehicle: 'vehicleId' })
      .then((res: TachographModel[]) => {
        if (res.length > 0) {
          const result = res.filter(
            (f) =>
              f.status == TachographStatus.CONDUCAO ||
              f.status === TachographStatus.INICIO
          );
          const r = this.utilsSrv.sortArrayByDate(result, 'date', -1);
          return r;
        } else return [];
      });
  }

  getLastestTachograph(
    userId: string,
    vehicle: boolean = false,
    companyId: any = null
  ): TachographModel {
    let where: any = {
      userId,
    };
    if (vehicle) {
      where['status'] = TachographStatus.CONDUCAO;
      if (companyId) where['companyId'] = companyId;
    }
    return (
      this.databaseSrv.db.tachograph
        .where(where)
        // .reverse()
        // .sortBy('date')
        .with({ company: 'companyId', vehicle: 'vehicleId' })
        .then((res: TachographModel[]) => {
          if (res.length > 0) {
            const r = this.utilsSrv.sortArrayByDate(res, 'date', -1);
            return r[0];
          } else return null;
        })
    );
  }

  getLastestTachographSynced(
    userId: string,
  ): TachographModel {
    return (
      this.databaseSrv.db.tachograph
        .where({ userId })
        // .with({})
        .toArray()
        .then((res: TachographModel[]) => {
          let tachographs = res.filter((t) => t?.pending == false)
          tachographs = this.utilsSrv.sortArrayByDate(tachographs, 'date', -1);
          return tachographs && tachographs.length > 0 && tachographs[0]
            ? tachographs[0]
            : null;
        })
    );
  }

  getLastestTachographSyncedTsUpdated(
    userId: string,
  ): TachographModel {
    return (
      this.databaseSrv.db.tachograph
        .where({ userId })
        // .with({})
        .toArray()
        .then((res: TachographModel[]) => {
          let tachographs = res.filter((t) => t?.pending == false)
          tachographs = this.utilsSrv.sortArrayByDate(tachographs, 'tsUpdated', -1);
          return tachographs && tachographs.length > 0 && tachographs[0]
            ? tachographs[0]
            : null;
        })
    );
  }

  getLastTachographBasedOnCurrent(
    id: string,
    userId: string,
    vehicle: boolean = false,
    companyId: any = null
  ): TachographModel {
    let where: any = {
      userId,
    };
    if (vehicle) {
      where['status'] = TachographStatus.CONDUCAO;
      where['companyId'] = companyId;
    }
    return (
      this.databaseSrv.db.tachograph
        .where(where)
        // .reverse()
        // .sortBy('date')
        .with({ company: 'companyId', vehicle: 'vehicleId' })
        .then((res: TachographModel[]) => {
          if (res.length > 0) {
            const result = res.filter((f) => f.id !== id);
            if (result.length > 0) {
              const r = this.utilsSrv.sortArrayByDate(result, 'date', -1);
              return r[0];
            } else return null;
          } else return null;
        })
    );
  }

  async saveTachograph(userId: string, tachograph: TachographModel) {
    //console.time('saveTachograph: currentService');
    let currentService = await this.journeySrv.getCurrent(
      userId,
      tachograph.companyId
    );
    //console.timeLog('saveTachograph: currentService');

    tachograph.tsCreated = new Date();
    tachograph.tsUpdated = new Date();

    //console.time('saveTachograph: getLastestTachograph');
    const lastest = await this.getLastestTachograph(userId);
    //console.timeLog('saveTachograph: getLastestTachograph');
    if (lastest) {
      //console.time('saveTachograph: this.databaseSrv.db.tachograph.update');
      await this.databaseSrv.db.tachograph.update(lastest.id, {
        endDate: tachograph.date,
        journey: currentService?.id || null,
        pending: true,
      });
      //console.timeLog('saveTachograph: this.databaseSrv.db.tachograph.update');
    }

    if (
      currentService &&
      tachograph.status == TachographStatus.CONDUCAO &&
      (tachograph.vehicleId || tachograph.licensePlate)
    ) {
      let vAux: any = null;
      if (tachograph.vehicleId) {
        //console.time('saveTachograph: this.vehicleSrv.getVehicleById');
        vAux = await this.vehicleSrv.getVehicleById(tachograph.vehicleId);
        //console.timeLog('saveTachograph: this.vehicleSrv.getVehicleById');
        // vAux = await this.vehicleSrv.getVehicle(
        //   tachograph.vehicleId,
        //   tachograph.companyId
        // );
      }
      if (
        !tachograph.vehicleId &&
        (!tachograph.licensePlate || tachograph.licensePlate.trim() == '')
      ) {
        //console.time('saveTachograph: this.vehicleSrv.getVehicle');
        vAux = await this.vehicleSrv.getVehicle(
          tachograph.licensePlate!,
          tachograph.companyId
        );
        //console.timeLog('saveTachograph: this.vehicleSrv.getVehicle');
      }

      if (
        currentService.vehicles.filter((v: any) =>
          vAux == null
            ? v.licensePlate == tachograph.licensePlate! && v.id == null
            : v.id == vAux.id
        ).length == 0
      ) {
        const obj = {
          id: vAux == null ? null : vAux.id,
          licensePlate:
            vAux == null ? tachograph.licensePlate : vAux.licensePlate,
          kms: tachograph.km,
          endKms: null,
        };

        currentService.vehicles.push(obj);

        await this.journeySrv.update(currentService);
      }
    }

    if (
      tachograph.status == TachographStatus.CONDUCAO &&
      tachograph?.vehicleId
    ) {
      //console.time('saveTachograph: this.vehicleSrv.getVehicle');
      const vehicle = await this.vehicleSrv.getVehicle(
        tachograph.vehicleId as any,
        tachograph.companyId
      );
      //console.timeLog('saveTachograph: this.vehicleSrv.getVehicle');
      if (vehicle && vehicle.id) {
        tachograph.vehicleId = vehicle.id;
      }
    }

    //console.time('saveTachograph: const existTachograph = await this.databaseSrv.db.tachograph');
    const existTachograph = await this.databaseSrv.db.tachograph
      .where({ id: tachograph.id })
      .with({});
    //console.timeLog('saveTachograph: const existTachograph = await this.databaseSrv.db.tachograph');
    if (existTachograph.length > 0) return;

    //console.time('saveTachograph: return this.databaseSrv.db')
    return this.databaseSrv.db.tachograph.add(tachograph);
    /*return this.databaseSrv.db
      .transaction('rw', this.databaseSrv.db.tachograph, () => {
        //console.timeLog('saveTachograph: return this.databaseSrv.db')
      })
      .catch((err: any) => {
        //console.timeLog('rsaveTachograph: return this.databaseSrv.db')
        console.log(
          '%ctachograph.service.ts line:109 err',
          'color: #007acc;',
          err
        );
      });*/
  }

  async changeTachographVehicle(
    date: Date,
    vehiclePlate: string,
    otherVehicle: boolean,
    licensePlate: string,
    userId: string,
    companyId: string,
    endKm: number,
    km: number
  ) {
    const lastest: any = await this.getLastestTachograph(userId);

    const lastestDriving: any = await this.getLastestTachograph(
      userId,
      true,
      companyId
    );

    if (lastest) {
      const dataLastest: any = {
        endDate: date,
        pending: true,
      };
      if (lastest && lastestDriving && lastest?.id === lastestDriving?.id) {
        dataLastest['endKm'] = endKm;
      }

      dataLastest['tsCreated'] = new Date();
      dataLastest['tsUpdated'] = new Date();

      await this.databaseSrv.db.tachograph.update(lastest.id, dataLastest);
    }

    if (lastestDriving) {
      lastestDriving.tsCreated = new Date();
      lastestDriving.tsUpdated = new Date();

      await this.databaseSrv.db.tachograph.update(lastestDriving.id, {
        endKm,
        pending: true,
      });
    }

    if (
      lastest &&
      lastestDriving &&
      lastest?.id === lastestDriving?.id &&
      lastest?.vehicleId
    ) {
      await this.vehicleSrv.updateVehicleKm(lastest.vehicleId, endKm);
    }

    let vehicle = null;
    if (otherVehicle == false) {
      vehicle = await this.vehicleSrv.getVehicle(vehiclePlate, companyId);
      this.vehicleSrv.updateVehicleKm(vehicle.id, km);
    }
    if (
      otherVehicle == true ||
      (otherVehicle == false && vehicle && vehicle.id)
    ) {
      const newTachograph: TachographModel = {
        ...lastest,
      };
      const newId = this.utilsSrv.getUUID();
      newTachograph.id = newId;
      newTachograph.sync = this.utilsSrv.isOnline() ? 'ON' : 'OFF';
      newTachograph.device = localStorage.getItem('deviceId')!;
      newTachograph.status = TachographStatus.CONDUCAO;
      newTachograph.appVersion = environment.version;
      newTachograph.date = date;
      newTachograph.endDate = null;
      newTachograph.vehicleId = otherVehicle == true ? null : vehicle?.id;
      newTachograph.licensePlate =
        otherVehicle == true ? licensePlate : vehicle?.licensePlate;
      newTachograph.companyId = companyId;
      newTachograph.km = km;
      newTachograph.pending = true;
      newTachograph.originalId =
        lastest?.status && lastest?.status == TachographStatus.CONDUCAO ? lastest.id : newId;
      newTachograph.originalDate =
        lastest?.status && lastest?.status == TachographStatus.CONDUCAO ? lastest.date : date;
      newTachograph.tsCreated = new Date();
      newTachograph.tsUpdated = new Date();

      return this.databaseSrv.db
        .transaction('rw', this.databaseSrv.db.tachograph, () => {
          return this.databaseSrv.db.tachograph.add(newTachograph);
        })
        .catch((err: any) => {
          console.log(
            '%ctachograph.service.ts line:109 err',
            'color: #007acc;',
            err
          );
        });
    }
  }

  async updateTachographEndKm(tachographId: string, endKm: number) {
    await this.databaseSrv.db.tachograph.update(tachographId, {
      tsUpdated: new Date(),
      endKm: endKm,
      pending: true,
    });
  }

  async updateTachographEndDate(tachographId: string, endDate: any) {
    await this.databaseSrv.db.tachograph.update(tachographId, {
      tsUpdated: new Date(),
      endDate,
      pending: true,
    });
  }

  async updateTachograph(
    userId: string,
    tachograph: TachographModel,
    originalDate: Date
  ): Promise<boolean> {
    let currentService = await this.journeySrv.getCurrent(
      userId,
      tachograph.companyId
    );

    if (
      currentService &&
      tachograph.status == TachographStatus.CONDUCAO &&
      (tachograph.vehicleId || tachograph.licensePlate)
    ) {
      let vAux: any = null;
      if (tachograph.vehicleId) {
        vAux = await this.vehicleSrv.getVehicleById(tachograph.vehicleId);
        // vAux = await this.vehicleSrv.getVehicle(
        //   tachograph.vehicleId,
        //   tachograph.companyId
        // );
      }
      if (
        !tachograph.vehicleId &&
        (!tachograph.licensePlate || tachograph.licensePlate.trim() == '')
      ) {
        vAux = await this.vehicleSrv.getVehicle(
          tachograph.licensePlate!,
          tachograph.companyId
        );
      }

      if (
        currentService.vehicles.filter((v: any) =>
          vAux == null
            ? v.licensePlate == tachograph.licensePlate! && v.id == null
            : v.id == vAux.id
        ).length == 0
      ) {
        const obj = {
          id: vAux == null ? null : vAux.id,
          licensePlate:
            vAux == null ? tachograph.licensePlate : vAux.licensePlate!,
          kms: tachograph.km,
          endKms: null,
        };

        currentService.vehicles.push(obj);

        await this.journeySrv.update(currentService);
      }
    } else if (
      currentService &&
      tachograph.status !== TachographStatus.CONDUCAO &&
      (tachograph.vehicleId || tachograph.licensePlate)
    ) {
      let registerVehicles = await this.getTachographWithVehicleByJourneyId(
        currentService.id
      );
      registerVehicles = registerVehicles.filter(
        (f: any) => f.id != tachograph.id
      );

      let vAux: any = null;
      if (tachograph.vehicleId) {
        vAux = await this.vehicleSrv.getVehicleById(tachograph.vehicleId);
        // vAux = await this.vehicleSrv.getVehicle(
        //   tachograph.vehicleId,
        //   tachograph.companyId
        // );
      }
      if (
        !tachograph.vehicleId &&
        (!tachograph.licensePlate || tachograph.licensePlate.trim() == '')
      ) {
        vAux = await this.vehicleSrv.getVehicle(
          tachograph.licensePlate!,
          tachograph.companyId
        );
      }

      let registersSameVehicle = registerVehicles.filter((f) =>
        vAux
          ? f.vehicleId === vAux.id ||
          (f.licensePlate &&
            f.licensePlate.trim() !== '' &&
            f.licensePlate === vAux.licensePlate)
          : f.licensePlate &&
          f.licensePlate.trim() !== '' &&
          tachograph.licensePlate &&
          tachograph.licensePlate.trim() == '' &&
          f.licensePlate === tachograph.licensePlate
      );

      // Check all registers from this journey with vehicles if exists another with same vehicle, if not remove vehicle from journey
      if (
        registersSameVehicle &&
        Array.isArray(registersSameVehicle) &&
        registersSameVehicle.length == 0
      ) {
        currentService.vehicles = currentService.vehicles.filter((v: any) =>
          vAux == null
            ? v.licensePlate != tachograph.licensePlate
            : v.id != vAux.id
        );

        // Update last vehicle with endKm = null is status is CONDUCAO
        const lastVehicle = registerVehicles[0];
        if (lastVehicle) {
          if (lastVehicle?.status == TachographStatus.CONDUCAO) {
            await this.databaseSrv.db.tachograph.update(lastVehicle.id, {
              tsUpdated: new Date(),
              endKm: null,
              pending: true,
            });
          }
          const index = currentService.vehicles.findIndex(
            (f) =>
              f.id == lastVehicle.vehicleId ||
              (f.licensePlate &&
                f.licensePlate.trim() !== '' &&
                lastVehicle.licensePlate &&
                lastVehicle.licensePlate.trim() == '' &&
                f.licensePlate === lastVehicle.licensePlate)
          );
          if (index > -1) currentService.vehicles[index].endKms = null;
        }

        await this.journeySrv.update(currentService);
      }

      tachograph.vehicleId = null;
      tachograph.otherVehicle = false;
      tachograph.licensePlate = '';
      tachograph.km = null;
      tachograph.endKm = null;
    }

    if (tachograph.status !== TachographStatus.DISPONIBILIDADE) {
      tachograph.teamDriving = false;
    }

    if (tachograph.status !== TachographStatus.DESCANSO) {
      tachograph.restType = null;
    }

    tachograph.pending = true;
    if (
      tachograph.status == TachographStatus.CONDUCAO &&
      tachograph?.vehicleId
    ) {
      const vehicle = await this.vehicleSrv.getVehicle(
        tachograph.vehicleId as any,
        tachograph.companyId
      );
      if (vehicle && vehicle.id) {
        tachograph.vehicleId = vehicle.id;
      }
    }

    //let lower: TachographModel[] = await this.databaseSrv.db.tachograph
    //  .where('date')
    //  .below(originalDate)
    //  .toArray();
    //if (lower.length > 0) {
    //  lower = this.utilsSrv.sortArrayByProperty(lower, 'date', -1);
    //  if (lower[0].date >= tachograph.date) {
    //    M.toast({
    //      html: 'A data não pode ser igual ou inferior ao registo anterior.',
    //    });
    //    return false;
    //  }
    //  await this.databaseSrv.db.tachograph.update(lower[0].id, {
    //    endDate: tachograph.date,
    //    pending: true,
    //  });
    //}

    // tachograph.originalDate = tachograph.date;
    if (tachograph?.originalId) {
      const originalTachograph = await this.getTachograph(
        tachograph.originalId
      );
      if (
        originalTachograph &&
        originalTachograph?.status !== tachograph?.status
      ) {
        tachograph.originalDate = tachograph.date;
      } else {
        if (
          tachograph.status === TachographStatus.DESCANSO &&
          tachograph?.restType == originalTachograph?.restType
        ) {
          tachograph.originalDate = originalTachograph.originalDate;
        } else tachograph.originalDate = tachograph.date;
      }
    }

    tachograph.tsUpdated = new Date();

    await this.databaseSrv.db.tachograph.update(tachograph.id, tachograph);

    let auxBanana = await this.databaseSrv.db.tachograph
      .where({ originalId: tachograph.originalId, status: tachograph.status })
      .toArray();
    auxBanana = auxBanana.filter(
      (f: any) =>
        f.status === TachographStatus.DESCANSO &&
        f?.restType == tachograph?.restType
    );
    // console.warn('auxBanana', auxBanana);
    for (const ab of auxBanana) {
      await this.databaseSrv.db.tachograph.update(ab.id, {
        originalDate: tachograph.originalDate,
        pending: true,
      });
    }

    return true;
  }

  getTacographHistory(
    userId: string,
    dateFrom: Date,
    dateTo: Date
  ): Promise<TachographModel[]> {
    if (dateFrom > dateTo) {
      let aux = dateFrom;
      dateFrom = dateTo;
      dateTo = aux;
    }

    if (dateFrom) {
      dateFrom.setHours(0);
      dateFrom.setMinutes(0);
      dateFrom.setSeconds(0);
    }

    if (dateTo) {
      dateTo.setHours(23);
      dateTo.setMinutes(59);
      dateTo.setSeconds(59);
    }

    return this.databaseSrv.db.tachograph
      .where({ userId })
      .with({ company: 'companyId', vehicle: 'vehicleId' })
      .then((res: TachographModel[]) => {
        res = res.filter(
          (r: TachographModel) => r.date >= dateFrom && r.date <= dateTo
        );
        return this.utilsSrv.sortArrayByProperty(res, 'date', -1);
      });
  }

  getCurrentDrivingTachographs(userId: string) {
    return this.databaseSrv.db.tachograph
      .where({ userId, status: TachographStatus.CONDUCAO })
      .with({ company: 'companyId', vehicle: 'vehicleId' })
      .then((res: TachographModel[]) => {
        res = res.filter(
          (r: TachographModel) => r.endKm == null || r.endKm == 0
        );
        if (res.length > 0)
          return this.utilsSrv.sortArrayByProperty(res, 'date', -1)[0];
        else return null;
      });
  }

  getPendingTachographs(userId: string): any[] {
    return this.databaseSrv.db.tachograph
      .where({ userId })
      .toArray()
      .then((tachographs: TachographModel[]) => {
        return tachographs
          .filter((t) => t.pending == true)
          .map((t) => {
            return {
              id: t.id,
              status: t.status,
              userId: t.userId,
              companyId: t.companyId,
              vehicleId: t.vehicleId,
              licensePlate: t.licensePlate,
              date: t.date ? t.date.toISOString() : null,
              endDate: t.endDate ? t.endDate.toISOString() : null,
              km: t.km,
              endKm: t.endKm,
              notes: t.notes,
              place: t.place,
              restType: t.restType,
              latitude: t.latitude,
              longitude: t.longitude,
              journey: t.journey,
              teamDriving: t.teamDriving,
              originalId: t.originalId,
              originalDate: t.originalDate,
              sync: t.sync,
              device: t.device,
              appVersion: t.appVersion,
              tsCreated: t.tsCreated,
              tsUpdated: t.tsUpdated,
            };
          });
      })
      .catch((err: any) => {
        console.log(
          '%ctachograph.service.ts line:271 err',
          'color: #007acc;',
          err
        );
        return [];
      });
  }

  async updateOrCreateTachograph(tachograph: TachographModel) {
    //console.time('updateOrCreateTachograph: await this.getTachograph')
    const tachographAux = await this.getTachograph(tachograph.id);
    //console.timeLog('updateOrCreateTachograph: await this.getTachograph')
    if (tachographAux) {
      //console.time('updateOrCreateTachograph: db.tachograph.update')
      await this.databaseSrv.db.tachograph.update(tachograph.id, tachograph);
      //console.timeLog('updateOrCreateTachograph: db.tachograph.update')
    } else {
      //console.time('updateOrCreateTachograph: db.tachograph.add')
      await this.databaseSrv.db.tachograph.add(tachograph);
      //console.timeLog('updateOrCreateTachograph: db.tachograph.add')
    }
  }

  exportCsv(exportData: any) {
    let uri = `${environment.API_URL}/api/tachograph/export`;
    return this.http.patch<any>(uri, exportData, { headers: this.headers });
  }

  getWeekHistory(exportData: any) {
    let uri = `${environment.API_URL}/api/tachograph/weekHistory`;
    return this.http.post<any>(uri, exportData, { headers: this.headers });
  }

  deleteTachographsOlderThanDate(userId: string, date: Date) {
    const drivingTachs = this.databaseSrv.db.tachograph
      .where({ userId, status: TachographStatus.CONDUCAO })
      .toArray();

    let lastDriving: any = null;
    if (drivingTachs && Array.isArray(drivingTachs) && drivingTachs.length > 0) {
      const r = this.utilsSrv.sortArrayByDate(drivingTachs, 'date', -1);
      lastDriving = r[0];
    }

    const tachographsLessThan1Month = this.databaseSrv.db.tachograph
      .where('date')
      .above(date)
      .and(function (x: any) {
        return x.userId == userId && x.pending == false;
      })
      .toArray();

    let canDelete = false;
    let deleteDate = new Date(date);
    if (tachographsLessThan1Month && Array.isArray(tachographsLessThan1Month) && tachographsLessThan1Month.length > 5) {
      const last5Tach = tachographsLessThan1Month.slice(0, 5);
      const journeyId = last5Tach.find(f => f?.journey);
      canDelete = true;
      if (lastDriving && journeyId && lastDriving?.journey == journeyId) {
        deleteDate = last5Tach.find(f => f?.id == lastDriving?.id)
          ? new Date(lastDriving?.date)
          : new Date(last5Tach[last5Tach.length - 1]?.date);
      } else {
        deleteDate = last5Tach[last5Tach.length - 1]?.date;
      }
    } else {
      canDelete = false;
    }


    console.warn('canDelete', canDelete);
    if (canDelete) console.warn('deleteDate', deleteDate.toISOString());
    // Delete only if exists at least 5 or until last driving date
    if (canDelete) {
      return this.databaseSrv.db.tachograph
        .where('date')
        .below(deleteDate)
        .and(function (x: any) {
          return x.userId == userId && x.pending == false;
        })
        .delete();
    } else return null;
  }

  deleteTachograph(id: string) {
    return this.databaseSrv.db.tachograph
      .where({ id })
      .delete();
  }
  deleteTachographByCompany(userId: string, companyId: string) {
    return this.databaseSrv.db.tachograph
      .where({ userId, companyId })
      .delete();
  }
}
