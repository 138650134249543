import { Injectable } from '@angular/core';
import { UtilsService } from '../service/utils.service';
import { DatabaseService } from './database.service';

export class UpdateModel {
  public id!: string;
  public userId!: string;
  public date!: Date;
}

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    private readonly databaseSrv: DatabaseService,
    private readonly utilsSrv: UtilsService
  ) {}

  getLastestUpdate(userId: string): UpdateModel {
    let where: any = {
      userId,
    };
    return (
      this.databaseSrv.db.update
        .where(where)
        .with({ user: 'userId' })
        .then((res: UpdateModel[]) => {
          if (res.length > 0) {
            const r = this.utilsSrv.sortArrayByProperty(res, 'date', -1);
            return r[0];
          } else return null;
        })
    );
  }

  createUpdate(userId: string) {
    return this.databaseSrv.db
      .transaction('rw', this.databaseSrv.db.update, () => {
        return this.databaseSrv.db.update.add({
          userId,
          date: new Date()
        });
      })
      .catch((err: any) => {
        console.log(
          '%ctachograph.service.ts line:109 err',
          'color: #007acc;',
          err
        );
      });
  }
}
