import { Injectable } from '@angular/core';
import { UtilsService } from '../service/utils.service';
import { CompanyModel } from './company.service';
import { DatabaseService } from './database.service';

export class VehicleModel {
  public id!: string;
  public licensePlate!: string;
  public category!: string;
  public brand!: string;
  public model!: string;
  public deleted!: boolean;
  public km!: number;
  public companyId!: number;
  public company?: CompanyModel[];
  public pending!: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  constructor(
    private readonly databaseSrv: DatabaseService,
    private readonly utilsSrv: UtilsService
  ) {}

  async getVehicleOrInsert(
    licensePlate: string,
    companyId: string,
  ): Promise<any> {
    let vehicle = await this.getVehicle(licensePlate, companyId);
    if (vehicle) return vehicle;
    else {
      await this.insertVehicle(licensePlate, companyId);
      vehicle = this.getVehicle(licensePlate, companyId);
      if (vehicle) return vehicle;
    }
  }

  getCompanyVehicle(companyId: string): any {
    return this.databaseSrv.db.vehicle
      .where({ companyId })
      .toArray()
      .then((vehicles: VehicleModel[]) => {
        return vehicles;
      })
      .catch((err: any) => {
        console.log('%cvehicle.service.ts line:53 err', 'color: #007acc;', err);
        return null;
      });
  }

  getVehicle(licensePlate: string, companyId: string): any {
    return this.databaseSrv.db.vehicle
      .where({ licensePlate, companyId })
      .toArray()
      .then((vehicles: VehicleModel[]) => {
        if (vehicles.length > 0) return vehicles[0];
        else return null;
      })
      .catch((err: any) => {
        console.log('%cvehicle.service.ts line:53 err', 'color: #007acc;', err);
        return null;
      });
  }

  getVehicleById(vehicleId: string): any {
    return this.databaseSrv.db.vehicle
      .where({ id: vehicleId })
      .toArray()
      .then((vehicles: VehicleModel[]) => {
        if (vehicles.length > 0) return vehicles[0];
        else return null;
      })
      .catch((err: any) => {
        console.log('%cvehicle.service.ts line:77 err', 'color: #007acc;', err);
        return null;
      });
  }

  insertVehicle(licensePlate: string, companyId: string): Promise<any> {
    return this.databaseSrv.db
      .transaction('rw', this.databaseSrv.db.vehicle, () => {
        return this.databaseSrv.db.vehicle
          .add({
            id: this.utilsSrv.getUUID(),
            licensePlate,
            companyId,
            deleted: false,
            pending: true,
            km: 0
          })
          .then((vehicle: any) => {
            return vehicle;
          });
      })
      .catch((err: any) => {
        console.log(
          '%ctachograph.service.ts line:109 err',
          'color: #007acc;',
          err
        );
      });
  }

  getPendingVehicles(): any[] {
    return this.databaseSrv.db.vehicle
      .toArray()
      .then((vehicles: VehicleModel[]) => {
        return vehicles
          .filter((v) => v.pending == true && v.licensePlate != null)
          .map((v) => {
            return {
              id: v.id,
              licensePlate: v.licensePlate,
              km: v.km,
              companyId: v.companyId,
            };
          });
      })
      .catch((err: any) => {
        console.log('%cvehicle.service.ts line:53 err', 'color: #007acc;', err);
        return null;
      });
  }

  async updateOrCreateVehicle(vehicle: VehicleModel) {
    const vehicleAux = await this.getVehicleById(vehicle.id);
    if (vehicleAux) {
      await this.databaseSrv.db.vehicle.update(vehicle.id, vehicle);
    } else {
      await this.databaseSrv.db.vehicle.add(vehicle);
    }
  }

  async updateVehicleKm(vehicleId: string, km: number) {
    return this.databaseSrv.db.vehicle.update(vehicleId, {
      km,
      pending: true,
    });
  }
}
