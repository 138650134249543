import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { UtilsService } from '../service/utils.service';

export class LicenseModel {
  public id!: string;
  public status!: string;
  public companyId!: string;
  public companyUserId!: string;
  public from!: Date;
  public to!: Date;
}

@Injectable({
  providedIn: 'root'
})
export class LicensesService {

  constructor(
    private readonly databaseSrv: DatabaseService,
    private readonly utilsSrv: UtilsService
  ) { }
  async updateOrCreateLicense(License: LicenseModel) {
    const licenseAux = await this.getLicenseById(License.id);
    if (licenseAux) {
      await this.databaseSrv.db.licenses.update(License.id, License);
    } else {
      await this.databaseSrv.db.licenses.add(License);
    }
  }
  getLicenseById(LicenseId: string): any {
    return this.databaseSrv.db.licenses
      .where({ id: LicenseId })
      .toArray()
      .then((Licenses: LicenseModel[]) => {
        if (Licenses.length > 0) return Licenses[0];
        else return null;
      })
      .catch((err: any) => {
        console.log('%cLicense.service.ts line:41 err', 'color: #007acc;', err);
        return null;
      });
  }
  getAllLicenses(companyUserId: string, companyId: string): any {
    return this.databaseSrv.db.licenses
      .where({ companyUserId, companyId: companyId, })
      .toArray()
      .then((Licenses: LicenseModel[]) => {
        if (Licenses.length > 0) return Licenses;
        else return null;
      })
      .catch((err: any) => {
        console.log('%cLicense.service.ts line:41 err', 'color: #007acc;', err);
        return null;
      });
  }
  getActiveLicenseByUserAndCompany(companyUserId: string, companyId: string): LicenseModel | null {
    return this.databaseSrv.db.licenses
      .where({ companyUserId, companyId: companyId, status: "APPROVED" })
      .toArray()
      .then((Licenses: LicenseModel[]) => {
        if (Licenses.length > 0) return Licenses[0];
        else return null;
      })
      .catch((err: any) => {
        console.log('%cLicense.service.ts line:55 err', 'color: #007acc;', err);
        return null;
      });
  }

  async AlterStatusLicenseByUserAndCompany(companyUserId: string, companyId: string, licenseArray: LicenseModel[]) {
    try {                  
      const allLicenses = await this.databaseSrv.db.licenses
        .where({ companyUserId, companyId })
        .toArray();      
      const licenseIds = licenseArray.map(license => license.id);     
      const LicensesToExclude = allLicenses.filter((license: { id: string; }) => !licenseIds.includes(license.id));
      
      await Promise.all(LicensesToExclude.map(async (license: { id: any; }) => {
        await this.databaseSrv.db.licenses.update(license.id, { status: "INACTIVE" });
      }));
      return true
    } catch (err) {
      console.log('%cLicense.service.ts line:85 err', 'color: #007acc;', err);
      return [];
    }
  }
}
