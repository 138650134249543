import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { UtilsService } from '../service/utils.service';

export class ConfigurationsModel {
  public label!: string;
  public value!: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {

  constructor(
    private readonly databaseSrv: DatabaseService,
    private readonly utilsSrv: UtilsService
  ) { }
  async updateOrCreateConfigurations(Configurations: ConfigurationsModel) {
    const ConfigurationsAux = await this.getConfigurationsByLabel(Configurations.label);
    if (ConfigurationsAux) {
      await this.databaseSrv.db.configurations.update(Configurations.label, Configurations);
    } else {
      await this.databaseSrv.db.configurations.add(Configurations);
    }
  }
  getConfigurationsByLabel(Configuration: string): any {
    return this.databaseSrv.db.configurations
      .where({ label: Configuration })
      .toArray()
      .then((Configurations: ConfigurationsModel[]) => {
        if (Configurations.length > 0) return Configurations[0];
        else return null;
      })
      .catch((err: any) => {
        console.log('%cConfigurations.service.ts line:41 err', 'color: #007acc;', err);
        return null;
      });
  }
}